import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar1/Navbar'; // Import the Navbar component
import './Inipage.css';

function Inipage() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    function gotologin() {
        window.location.href = '/login';
    }


  return (
    <div className="Inipage">
        <Navbar/>
      <div className='contentIni'>
        {/* ícone jek */}
       
          {/* caixa do texto */}
          <div className='text-container'>
            <h1 className="title">
              <span className="blue-text">Hey jeKer, {windowWidth <= 768 && <br />}</span>
              this is <span className="blue-text">jeK</span>forms!
            </h1>
            <h2 className="texto">
                  Here you’ll be able to create forms for all the<br />events and activities you’re in charge of!
                </h2>
          </div>

    
        {/* botão */}
        <div className="Button-containerini" onClick={() => gotologin()}>
            <button className="Button" >Next {'>'}</button>
      </div>

      </div>
    </div>
  );
}

export default Inipage;
