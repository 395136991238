import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './Answer.css';
import Adress from './../../adress.js';
import Navbar from '../../components/Navbar2/Navbar2';
import { FaCogs } from "react-icons/fa";

function Answer() {
  const [respostas, setRespostas] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const formularioId = params.get('formulario_id');
  const fname = params.get('Fname');
  const email = params.get('email');
  const [selectedButton, setSelectedButton] = useState('summary');
  const [activeGroupIndex, setActiveGroupIndex] = useState(0); // Índice do grupo de respostas ativo

  useEffect(() => {
    const fetchRespostas = async () => {
      try {
        const response = await axios.get(`${Adress.backendUrl}/api/get_answer/${formularioId}`);
        setRespostas(response.data);
        console.log(response.data);
      } catch (error) {
        console.error('Erro ao obter as respostas:', error);
      }
    };
    fetchRespostas();
  }, [formularioId]);

  const countType3And6Answers = (answers) => {
    const count = {};
    const filteredAnswers = answers.filter(answer => answer.type === 3 || answer.type === 6);
  
    filteredAnswers.forEach(answer => {
      const key = answer.type === 6 ? 'Imagem' : 'pergunta';
      count[key] = count[key] || {};
      count[key][answer.response] = (count[key][answer.response] || 0) + 1;
    });
  
    return count;
  };

  // Função para agrupar respostas por Ponte
  const groupByRespostaPonte = (respostas) => {
    const groupedRespostas = {};
    
    respostas.forEach((resposta) => {
      const { Ponte } = resposta;
      if (!groupedRespostas[Ponte]) {
        groupedRespostas[Ponte] = [];
      }
      groupedRespostas[Ponte].push(resposta);
    });
    return groupedRespostas;
  };

  const respostaGroups = Object.values(groupByRespostaPonte(respostas));

  const nextGroup = () => {
    if (activeGroupIndex < respostaGroups.length - 1) {
      setActiveGroupIndex(activeGroupIndex + 1);
    }
  };

  const previousGroup = () => {
    if (activeGroupIndex > 0) {
      setActiveGroupIndex(activeGroupIndex - 1);
    }
  };
  const IndividualAnswer = () => {
    const activeGroup = respostaGroups[activeGroupIndex];
  
    // Verifica se o grupo de respostas ativo está vazio
    if (activeGroup && activeGroup.length > 0) {
      return (
        <div className="individual-page">
          {activeGroup.map((resposta) => (
            <div key={resposta.id} className="resposta-item">
              <div className='individual_pergunta'>{resposta.pergunta}</div>
              {resposta.tipo === 6 ? (
                <img src={`${Adress.backendUrl}${resposta.resposta}`} alt="Resposta Imagem" className='individual_resposta_imagem' />
              ) : (
                !resposta.imagem && <div className='individual_resposta'>{resposta.resposta}</div>
              )}
              {resposta.imagem && (
                <img src={`${Adress.backendUrl}${resposta.imagem}`} alt="Imagem de Resposta" className='individual_resposta_imagem'/>
              )}
            </div>
          ))}
        </div>
      );
    } else {
      // Exibe a mensagem quando não houver respostas
      return <div className="no-responses">Sem respostas por aqui...</div>;
    }
  };

  const answerCountsForType3And6 = countType3And6Answers(respostas);


  return (
    <div className="Answer">
      <Navbar />
      
      <div className='top_answer_page'>
        <div className='top_answer_page_wrapper'>
         <button 
           onClick={() => setSelectedButton('summary')}
           className={selectedButton === 'summary' ? 'button-selected' : ''}
         >
           Summary
         </button>
         <button 
           onClick={() => setSelectedButton('individual')}
           className={selectedButton === 'individual' ? 'button-selected' : ''}
         >
           Individual
         </button>
         </div>  
      </div>

      <div className='Forms_title'>
        <h1>{fname}</h1>
      </div>  

      <div className='Forms_title_answer'>
      {selectedButton === 'summary' ? (
          <div className='Summary_answer_page'>
            <div className ='construction-site'> 
              
            <FaCogs />
              
            </div>
            <h2>Work in Progress</h2>
          </div>
        ) : (
           <div className='Individual_answer_page'>
            <div className='button_answer_page'>
              <button onClick={previousGroup}>{'<'}</button>
              <h1 className='button_title'> Resposta {activeGroupIndex + 1}</h1>
              <button onClick={nextGroup}>{'>'}</button>
            </div>
              
            {IndividualAnswer()}
          </div>
        )}
      </div>  
    </div>
  );
}

export default Answer;
