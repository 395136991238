import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import jekforms from '../../assets/img/jekforms.svg';
import Adress from './../../adress.js';
import logojek2 from '../../assets/img/logojek2.svg';
import logojek3 from '../../assets/img/logojek3.svg';
import Downbutton from '../../assets/img/Downbutton.svg';
import Button from '../../components/Button/Button';
import Calendar from 'react-calendar';
import Rating from '@mui/material/Rating';

import { BsCheckCircle } from 'react-icons/bs';
import './FormShow.css';
import { Switch } from '@mui/material';
import { render } from 'react-dom';
import { SlCloudUpload } from "react-icons/sl";

function FormShow(change) {
  const [templateData, setTemplateData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentTemplateIndex, setCurrentTemplateIndex] = useState(0);
  const [idResposta, setIdResposta] = useState(null);
  const [count, setCount] = useState(0);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const formularioId = params.get('Fd');
  const mudarTemplate = params.get('change');
  const navigate = useNavigate();
  const [avaliacao, setAvaliacao] = useState('');
  const [avaliacaoEnviada, setAvaliacaoEnviada] = useState(false);
  const [respostas, setRespostas] = useState([]);
  const [respostaSeparada, setRespostaSeparada] = useState([]);
  const [value, setValue] = React.useState(0);  
  

  //constanste do template 3
  const [checkedItems, setCheckedItems] = useState({});
  const [mudar, setMudar] = useState(false);

  //constanste do template 4
  const [inputValue, setInputValue] = useState('');

  //constante do template 6
  const [escolhaUsuario, setEscolhaUsuario] = useState(null);

  //constante do template 7
  const [imagemSelecionada, setImagemSelecionada] = useState(null);


////////////////////////////////////////////////////////////////////////////////Templates://///////////////////////////////////////////////////////////////////////////////////// 

  useEffect(() => {
    const fetchTemplateData = async () => {
      try {
        const response = await fetch(`${Adress.backendUrl}/api/get-Template/${formularioId}/`);
        if (!response.ok) {
          throw new Error('Não foi possível obter os dados do template');
        }
        const data = await response.json();
        setTemplateData(data);
        console.log('Dados dos Templates recebidos:', data);

      } catch (error) {
        console.error('Erro ao obter os dados dos templates:', error);
      }
    };

    fetchTemplateData();
  }, [formularioId, ]);



  const goToNextTemplate = (template) => {
    const nextIndex = currentTemplateIndex + 1;
    console.log('Next index:', nextIndex);
    console.log('idResposta:', idResposta);

    const countTipo1e2 = templateData.slice(0, currentTemplateIndex).filter(t => t.tipo === 1 || t.tipo === 2).length;
    
    const respostaIndex = currentTemplateIndex - countTipo1e2;

    console.log('Resposta index:', respostaIndex);
    console.log('currenttemplate:', templateData.length);

    if(template.tipo === 1 || template.tipo === 2 ){
      return;
    }
    
    
    if(template.tipo === 3){
      template.perguntas_checkbox.forEach((pergunta) => {
        const opcaoSelecionada = pergunta.opcaoperguntacheckbox_set.find((opcao) =>
          isOptionChecked(pergunta.id, opcao.id)
        );

        const updatedRespostas = [...respostas];
        updatedRespostas[respostaIndex] = {
          idResposta: idResposta,
          tipo: templateData[currentTemplateIndex].tipo, 
          pergunta: template.titulo,
          resposta: opcaoSelecionada.escolha
        };
        setRespostas(updatedRespostas);
        console.log('Resposta:', updatedRespostas);
      });
    }
    else if(template.tipo === 4){
      const updatedRespostas = [...respostas];
      updatedRespostas[respostaIndex] = {
          idResposta: idResposta,
          tipo: templateData[currentTemplateIndex].tipo, 
          pergunta: template.titulo,
          resposta: inputValue
      };
      setRespostas(updatedRespostas);
      console.log('Resposta:', updatedRespostas);
    }
    else if(template.tipo === 5){
      const formattedDate = selectedDate.toISOString().slice(0, 10); 
      const updatedRespostas = [...respostas];
      updatedRespostas[respostaIndex] = {
        idResposta: idResposta,
        tipo: templateData[currentTemplateIndex].tipo, 
        pergunta: template.titulo,
        resposta: formattedDate 
      };
      setRespostas(updatedRespostas);
      console.log('Resposta:', updatedRespostas);
    }
    else if(template.tipo === 6){
      const updatedRespostas = [...respostas];
      updatedRespostas[respostaIndex] = {
        idResposta: idResposta,
          tipo: templateData[currentTemplateIndex].tipo, 
          pergunta: template.titulo,
          resposta: template.imagens_template6[escolhaUsuario].image
      };
      setRespostas(updatedRespostas);
      console.log('Resposta:', updatedRespostas);
    }else if(template.tipo === 7){

      const formData = new FormData();
      formData.append('formulario_id',template.id);
      formData.append('tipo', template.tipo);
      formData.append('pergunta', template.titulo);
      formData.append('resposta', imagemSelecionada);
      
      const updatedRespostas = [...respostas];
      updatedRespostas[respostaIndex] = {
        idResposta: idResposta,
          tipo: templateData[currentTemplateIndex].tipo, 
          pergunta: template.titulo,
          resposta: imagemSelecionada
      };
      setRespostas(updatedRespostas);
      console.log('Resposta:', updatedRespostas);
    }


  
    if (nextIndex < templateData.length) {
      setCurrentTemplateIndex(nextIndex);
    } else {
      setCurrentTemplateIndex(templateData.length);
      
    }

  };

const SendAnswer = () => {
  console.log("alo");
  const countTipo1e2 = templateData.slice(0, currentTemplateIndex).filter(t => t.tipo === 1 || t.tipo === 2).length+1;
    
   
  for(let i = 0; i < respostas.length-2; i++){
    if(respostas[i] === null){
      alert(`Preencha a pergunta ${i+countTipo1e2}`);
      return;
    }
    }

  for(let i = 0; i < respostas.length; i++){


    if(respostas[i] === null){
    continue;
    }
    
  switch (respostas[i].tipo) {
    
    case 3:
      SendTemplate3Answer(respostas[i]);
      console.log("alo3");
      break;
    case 4:
      SendTemplate4Answer(respostas[i]);
      console.log("alo4");
      break;
    case 5:
      SendTemplate5Answer(respostas[i]);
      console.log("alo5");
      break;
    case 6:
      SendTemplate6Answer(respostas[i]);
      console.log("alo6");
      break;
    case 7:
      SendTemplate7Answer(respostas[i]);
      console.log("alo7");
      break;
    default:
      console.log("alo8");
      break;
  }
}
setAvaliacaoEnviada(true);
setAvaliacao(value);
return;
};
  

  const goToPreviousTemplate = () => {
    setCurrentTemplateIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

 
  useEffect(() => {
    const numTemplatesQueExigemResposta = templateData.filter(template => template.tipo !== 1 && template.tipo !== 2).length;
    const updatedRespostas = new Array(numTemplatesQueExigemResposta).fill(null);
    setRespostas(updatedRespostas);
  }, [templateData]);

////////////////////////////////////////////////////////////////////////Lógica de envio para Bd:///////////////////////////////////////////////////////////////////////////////////////////////

const SendTemplate3Answer = async (respostas) => {
  try {
    const formData = new FormData();

        console.log(respostas.resposta);   

        formData.append('formulario_id',respostas.idResposta);
        formData.append('tipo', respostas.tipo);
        formData.append('pergunta', respostas.pergunta);
        formData.append('resposta', respostas.resposta);
        
    const response = await axios.post(`${Adress.backendUrl}/api/answer-Form/`, formData);

    console.log(response.data);
    
  } catch (error) {
    console.error(error);
  }
};


const SendTemplate4Answer= async (respostas) => {
  try { 

    const formData = new FormData();
    formData.append('formulario_id',respostas.idResposta);
    formData.append('tipo', respostas.tipo);
    formData.append('pergunta', respostas.pergunta);
    formData.append('resposta', respostas.resposta);
    

    const response = await axios.post(`${Adress.backendUrl}/api/answer-Form/`, formData);

    console.log(response.data);
    setInputValue('');
    
  } catch (error) {
    console.error(error);
  }
};


const SendTemplate5Answer = async (respostas) => {
  try {
   
    const formattedDate = selectedDate.toISOString();

    const formData = new FormData();

    formData.append('formulario_id',respostas.idResposta);
    formData.append('tipo', respostas.tipo);
    formData.append('pergunta', respostas.pergunta);
    formData.append('resposta', respostas.resposta);
    

  const response = await axios.post(`${Adress.backendUrl}/api/answer-Form/`, formData);

    console.log('Data enviada para a base de dados:', formattedDate);
    console.log('Resposta da API:', response.data);
  
  } catch (error) {
    console.error('Erro ao enviar a data para a base de dados:', error);
  }
};

const SendTemplate6Answer = async (respostas) => {
  try {
   

     const formData = new FormData();
     formData.append('formulario_id',respostas.idResposta);
     formData.append('tipo', respostas.tipo);
     formData.append('pergunta', respostas.pergunta);
     formData.append('resposta', respostas.resposta);
     
     
      const response = await axios.post(`${Adress.backendUrl}/api/answer-Form/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Resposta da API:', response.data);
      
   
    }
   catch (error) {
    console.error('Erro ao enviar a escolha do usuário para a base de dados:', error);
  }
};


const SendTemplate7Answer = async (respostas) => {
  try {
    const formData = new FormData();
    formData.append('formulario_id',respostas.idResposta);
    formData.append('tipo', respostas.tipo);
    formData.append('pergunta', respostas.pergunta);
    formData.append('imagem', respostas.resposta);
    

    const response = await axios.post(`${Adress.backendUrl}/api/answer-Form/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    console.log('Resposta da API:', response.data);
    console.log('Resposta enviada para a base de dados');
    setImagemSelecionada(null);
  
  } catch (error) {
    console.error('Erro ao enviar a resposta para a base de dados:', error);
  }
};


////////////////////////////////////////////////////////////////////////////////Template1(imagem e título)///////////////////////////////////////////////////////////////////////////////////////  

  const renderTemplate1op = (template) => {
    const imagemUrl = `${Adress.backendUrl}${template.imagem}`;
    return (
      <div className='Template1OP1'>
  
        <div className='left1'>
           
              <img src={jekforms} alt="jekforms" className="jekformsTOP" />
            
            <div className='contentOP1'>
              <h1>{template.titulo}</h1>
              <button alt="Continua" className="ContinuarBtnOP1"  onClick={goToNextTemplate}>Continuar</button>
            </div>
        </div> 

        <div className='right1'>
            <div className='imagenzinha'>
             <img src={imagemUrl} alt='imagemnzinha'/>
             </div>
          </div>
        
  
      </div>
    );
};

////////////////////////////////////////////////////////////////////////////////Template2(título)//////////////////////////////////////////////////////////////////////////////////////////////////  

const renderTemplate2op = (template) => {
  return (
    <div className='Template2OP'>

      
      <img src={jekforms} alt="jekforms" className="jekformsTOP" />  
      
      <div className='contentOP2'>
       <h1>{template.titulo}</h1>  
       <button alt="Continua" className="ContinuarBtnOP2"  onClick={goToNextTemplate}>Continuar</button>
      </div>
    </div>
  );
};


////////////////////////////////////////////////////////////////////////Template3(checbox) e Lógica:////////////////////////////////////////////////////////////////////////////////////////////////

const handleCheckboxChange = (perguntaId, opcaoId) => {
  setCheckedItems((prevState) => ({
    ...prevState,
    [perguntaId]: opcaoId,
  }));
};

const isOptionChecked = (perguntaId, opcaoId) => {
  return checkedItems[perguntaId] === opcaoId;
};


const handleOptionClick = (perguntaId, opcaoId) => {
  const newCheckedItems = { ...checkedItems };

  if (newCheckedItems[perguntaId] === opcaoId) {
    
    newCheckedItems[perguntaId] = null;
  } else {
    
    newCheckedItems[perguntaId] = opcaoId;
  }

  setCheckedItems(newCheckedItems);
  
};



const renderTemplate3op = (template) => {
  return (
    <div className="Templateop3">

      <div className='left3'>
      <img src={jekforms} alt="jekforms" className="jekformsTOP" />

      <div className="contentOP3">
        <h2 className="perguntatitle">
          <span className="blue-text">{template.titulo}</span>
        </h2>

        
          {template.perguntas_checkbox.map((pergunta) => (
            <div key={pergunta.id} className="pergunta-container">
              {pergunta.opcaoperguntacheckbox_set.map((opcao) => (
                <div key={opcao.id} className='caixa-size'>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      hidden
                      checked={isOptionChecked(pergunta.id, opcao.id)}
                      onChange={() => handleCheckboxChange(pergunta.id, opcao.id)}
                    />
                    <button
                      className="caixacheck"
                      onClick={() => handleOptionClick(pergunta.id, opcao.id)}
                    >
                      {opcao.escolha}
                      {isOptionChecked(pergunta.id, opcao.id) && (
                        <div className="checkedbox1">
                          <BsCheckCircle />
                        </div>
                      )}
                    </button>
                  </label>
                </div>
              ))}
            </div>
          ))}
        

       
      </div>
       <div className="Button-containerop4" >
                      <button className="ButtonBack" onClick={() => goToPreviousTemplate(template)}>{'<'}Back</button>
                      <button className="ButtonNext"  onClick={() => goToNextTemplate(template)}>Next {'>'}</button>
                </div>
           
      </div>
     
    </div>
  );
};


////////////////////////////////////////////////////////////////////////Template4(pergunta) e Lógica:///////////////////////////////////////////////////////////////////////////////////////////////


const renderTemplate4op = (template) => {

  return (
    <div className='Templateop4'>

      
      <img src={jekforms} alt="jekforms" className="jekformsTOP" />
      
      
       

       
            <div className='middlet4'>
                <div className='contentOP4'>
                  <h2 className='titulo4'> <span className="blue-text">{template.titulo}</span></h2>
                  <input
                    type="text"
                    value={inputValue}
                    className="inputop4"
                    onChange={(e) => setInputValue(e.target.value)} 
                  />
                </div>
            </div>
            
                <div className="Button-containerop4" >

                <button className="ButtonBack" onClick={() => goToPreviousTemplate(template)}>{'<'}Back</button>
                      <button className="ButtonNext" onClick={() => goToNextTemplate(template)}>Next {'>'}</button>
                </div>
           
       

       </div>  
      

   
  );

  };

////////////////////////////////////////////////////////////////////////Template5(calendario) e Lógica:///////////////////////////////////////////////////////////////////////////////////////////////

const handleDateChange = (date) => {
  setSelectedDate(date);
};


const renderTemplate5op = (template) => {
  return (
    <div className='Template5op'>
      <img src={jekforms} alt="jekforms" className="jekformsTOP" />

        <div className='top5'>
          <div className='contentOP5'>
            <h2 className='titulotop5'> <span className="blue-text">{template.titulo}</span> </h2>

            <div className="quadradoWrapperOP5">
              <div className='calendar'>
                <Calendar onChange={handleDateChange} value={selectedDate} />
              </div>


            </div>
          </div>
        </div>  
       
        <div className="Button-containerop4" >
        <button className="ButtonBack" onClick={() => goToPreviousTemplate(template)}>{'<'}Back</button>
            <button className="ButtonNext" onClick={() => goToNextTemplate(template)}>Next {'>'}</button>
        </div>
       
    </div>
  );
  }

////////////////////////////////////////////////////////////////////////Template6(imagens) e Lógica:///////////////////////////////////////////////////////////////////////////////////////////////

const handleImagemClick = (index) => {
   
  setEscolhaUsuario(index);
};



const renderTemplate6op = (template) => {
  return (
    <div className='Templateop6'>
      <img src={jekforms} alt="jekforms" className="jekformsTOP" />
        
          
            <h2 className='titulotop6'> <span className="blue-text">{template.BsCheckCircletitulo}</span> </h2>
            <div className="imagensWrapper">
              {template.imagens_template6.map((imagem, index) => (
                <div  id={`Imagem-${index + 1}`} key={index} onClick={() => handleImagemClick(index)}>
                  <img
                    src={`${Adress.backendUrl}${decodeURIComponent(imagem.image)}`}
                    alt={`Imagem-${index + 1}`}
                    className={`imagens-${escolhaUsuario === index ? "selecionada" : ""}`}
                  />
                </div>
              ))}
           
         
        </div>
      <div className="Button-containerop4">
      <button className="ButtonBack" onClick={() => goToPreviousTemplate(template)}>{'<'}Back</button>
        <button className="ButtonNext"  onClick={() => goToNextTemplate(template)}>Next {'>'}</button>
      </div>
    </div>
  );
}

////////////////////////////////////////////////////////////////////////Template7(Download) e Lógica:///////////////////////////////////////////////////////////////////////////////////////////////



const handleImageChange = (event) => {
  setImagemSelecionada(event.target.files[0]);
};

const renderTemplate7op = (template) => {
  return (
    <div className='Template7op'>
      <img src={jekforms}  alt="jekforms" className="jekformsTOP"/>

      <div className='contentOP7'>
        <h2 className='titulotop7'>
          <span className="blue-text">{template.titulo}</span>
        </h2>
        <div className="quadradoWrapperOP7">
          <label htmlFor="imagemInput" className='labelinput'>
          <SlCloudUpload />
          </label>
          <input
            type="file"
            id="imagemInput"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleImageChange}
          />
        </div>
      </div>

      <div className="Button-containerop4">
      <button className="ButtonBack" onClick={() => goToPreviousTemplate(template)}>{'<'}Back</button>
        <button className="ButtonNext" onClick={() => goToNextTemplate(template)} >Next {'>'}</button>
      </div>
    </div>
  );
};

//////////////////////////////////////////////////////////////////////Template8(Avaliação) e Lógica:///////////////////////////////////////////////////////////////////////////////////////////////

const renderavaltemplate = () => {
  console.log("dentro");
  return (
    <div className='avalpage'>
      <img src={jekforms} alt="jekforms" className="jekformsTOP" id="2" />
    

      
        {avaliacaoEnviada ? (
          <h1 className='avaltitle'>Obrigado pela avaliação!</h1>
        ) : (
          <>
          <div className='avalg'>
            <h1 className='avaltitle'>O que achaste do Formulário?</h1>

            <div className='avalcontent'>
            <Rating
                name="simple-controlled"
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                className='avalrating'
              />
            </div>
           
            </div>
            <div className="Button-containerop4">
              
              <button className="ButtonBack" onClick={() => goToPreviousTemplate()}>{'<'}Back</button>
              <button className="ButtonNext" onClick={() => SendAnswer()}>Send {'>'}</button>
                  
          </div> 
          </>
          
        )}
       
   
    </div>
  );
              }

////////////////////////////////////////////////////////////////////////Renderização dos templates:///////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    // Verifica se é o primeiro template e se a resposta ainda não foi vinculada
    if (currentTemplateIndex === 0 && idResposta === null) {
      performVincularResposta(formularioId);
    }
  }, [currentTemplateIndex, idResposta, formularioId]);

const performVincularResposta = async (formulario_id) => {
  try {
    const response = await axios.put(`${Adress.backendUrl}/api/vincula-res/${formulario_id}`);
    
    if (response.status !== 200) {
      throw new Error(`Erro na requisição: ${response.status}`);
    }

    const data = response.data;
    console.log('Resposta da API:', data);
    setIdResposta(data.id);
  } catch (error) {
    console.error('Erro durante a requisição:', error.message);
  }
};




const renderCurrentTemplate = () => {
  const currentTemplate = templateData[currentTemplateIndex];

  if (currentTemplate === undefined) {
    return renderavaltemplate();
  }

  if(currentTemplateIndex === templateData.length){
    return renderavaltemplate();
  }

 
      switch (currentTemplate.tipo) {
          case 1:
            return renderTemplate1op(currentTemplate);
          case 2:
            return renderTemplate2op(currentTemplate);
          case 3:
            return renderTemplate3op(currentTemplate);
          case 4:
            return renderTemplate4op(currentTemplate);
          case 5:
            return renderTemplate5op(currentTemplate);  
          case 6:
            return renderTemplate6op(currentTemplate);
           case 7:
             return renderTemplate7op(currentTemplate);     
           default:
             return <h1>template desconhecido</h1>;
}
};

return (

     <div className="FormShow">
        {renderCurrentTemplate()}
      
     </div>
     

    );
}

export default FormShow;