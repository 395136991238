import React, { useState, useEffect } from 'react';  
import axios from 'axios';  
import jekforms from '../../assets/img/jekforms.svg';
import Downbutton from '../../assets/img/Downbutton.svg';

import Adress from '../../adress.js';
import './Download.css';


const Template7 = ({ buttonPressed, formularioId }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    if (!buttonPressed) {
      setInputValue(event.target.value);
    }
  };

  useEffect(() => {
    if (buttonPressed && inputValue) {
      enviarInputs();
    }
  }, [buttonPressed]);

  const enviarInputs = async () => {
    try {
      console.log(formularioId);
      const response = await axios.post(`${Adress.backendUrl}/api/criar-Template/`, {
        tipo: 7, 
        titulo: inputValue,
        formularioId: formularioId,
      });
      console.log('Inputs enviados para a base de dados');
      console.log('Valor do input:', inputValue);
      console.log('Resposta da API:', response.data);
      setInputValue('');
    } catch (error) {
      console.error('Erro ao enviar os inputs para a base de dados:', error);
    }
  };

  return (
    <div className='Template7'>
      <img src={jekforms} alt="jekforms" className="jekformsT7" />
      
      <div className='contentTt7'>
        <input type="text" name="Titulo" placeholder="Título da pergunta" value={inputValue} onChange={handleInputChange}/>

        
        <img src={Downbutton} alt="Botão de download" className="DownB" />
        
      </div>
    </div>
  );
};

export default Template7;
