import React, { useState, useEffect } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import './Loginpage.css';
import Navbar from '../../components/Navbar1/Navbar'; 


import Adress from './../../adress.js';

function Loginpage({ setEmail }) {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = async (profile) => {
    try {
      const res = await axios.post(`${Adress.backendUrl}/api/login/`, {
        username: profile.name,
        email: profile.email
      });
      console.log(res.data);

      
      await axios.put(`${Adress.backendUrl}/api/user-activation/${res.data.user_id}/`);

      setIsLoggedIn(true);
      const menuURL = `/menu?is_active=${res.data.is_active}&email=${profile.email}`;
      window.location.href = menuURL;
    } catch (error) {
      console.log(profile);
      console.log(error);
    } 
  };

  const login = useGoogleLogin({
    clientId: '863812812346-1kn9rp3294v4dnb9gn2rr8tf33jpbhmj.apps.googleusercontent.com',
    onSuccess: async (response) => {
      setUser(response);
      if (response) {
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.access_token}`)
          .then((res) => {
            setProfile(res.data);
            setEmail(res.data.email); 
            handleLogin(res.data);
          })
          .catch((err) => console.log(err));
      }
    },
    onError: (error) => console.log('Login Failed:', error)
  });

  return (
    <div className="Login-container">
      <Navbar />
      <div className="signIn">
        <h1 className="titlelogin">Enter your jeknowledge credentials!</h1>
        <button className="google-btn" onClick={() => login()}>
          Sign in with Google
        </button>
      </div>

    </div>
  );
}

export default Loginpage;
