import React from 'react';
import jekforms from '../../assets/img/jekforms.svg';
import './Navbar.css';

const Navbar = () => {
    return (
        <div className='navbar'>
           <div className='navbar-container'>
            <img src={jekforms} className='navbar_logo' alt='jekforms logo' />
            </div>
        </div>
    );
};

export default Navbar;
