import React, { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './FormCreatepage.css';
import icon_1_1 from '../../assets/img/icon_1_1.png';
import icon_2_1 from '../../assets/img/icon_2_1.png';
import icon_3_1 from '../../assets/img/icon_3_1.png';
import icon_4_1 from '../../assets/img/icon_4_1.png';
import icon_5_1 from '../../assets/img/icon_5_1.png';
import icon_6_1 from '../../assets/img/icon_6_1.png';
import icon_7_1 from '../../assets/img/icon_7_1.png';
import jekforms from '../../assets/img/jekforms.svg';
import Modelos from '../../assets/img/Modelos.svg';
import Geral from '../../assets/img/Geral.svg';
import buttonplus from '../../assets/img/buttonplus.svg';
import Logout from '../../components/LogOut/Logout';
import TituloImage from '../../components/Templates/TituloImage';
import Titulo from '../../components/Templates/Titulo';
import Checkbox from '../../components/Templates/Checkbox';
import Pergunta from '../../components/Templates/Pergunta';
import Calendario from '../../components/Templates/Calendario';
import Imagens from '../../components/Templates/Imagens';
import Download from '../../components/Templates/Download';
import Navbar2 from '../../components/Navbar2/Navbar2.js';
import Adress from './../../adress.js';

function FormsCreatepage() {
  const [modeloSelected, setModeloSelected] = useState(true);
  const [geralSelected, setGeralSelected] = useState(false);
  const [templateComponent, setTemplateComponent] = useState(null);
  const [numPages, setNumPages] = useState(1);
  const [buttonPressed, setButtonPressed] = useState(false);
  const [formularioNome, setFormularioNome] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formularioId, setFormularioId] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get('email');

  const [escolha, setEscolha] = useState('normal'); 

  const handleFormularioNomeChange = (event) => {
    setFormularioNome(event.target.value);
  };

  const criarFormulario = async () => {

    console.log(email);
    try {
      const response = await axios.post(`${Adress.backendUrl}/api/criar-form/`, { nome: formularioNome, email: email});
      const formularioId = response.data.id;
      setFormularioId(formularioId);
      console.log('ID do formulário criado:', formularioId);
      return formularioId;

    } catch (error) {
      console.error('Erro ao criar o formulário:', error);
    }
  };

  
  const handlePage = () => {
    setNumPages(prevNumPages => prevNumPages + 1);
  
    if (templateComponent) {
      const updatedTemplateComponent = React.cloneElement(templateComponent, {
        buttonPressed: true,
        formularioId: formularioId // Passa o ID do formulário como prop
      });
      console.log('Estado do botão:', buttonPressed);

      setTemplateComponent(updatedTemplateComponent);
    }
    setButtonPressed(false);

  };
  

  const handleButtonClick = (template) => {
    let templateComponent = null;
  
    switch (template) {
      case 'template1':
        templateComponent = <TituloImage buttonPressed={buttonPressed} formularioId={formularioId} />;
        break;
      case 'template2':
        templateComponent = <Titulo buttonPressed={buttonPressed} formularioId={formularioId}/>;
        break;
      case 'template3':
        templateComponent = <Checkbox buttonPressed={buttonPressed} formularioId={formularioId}/>;
        break;
      case 'template4':
        templateComponent = <Pergunta buttonPressed={buttonPressed} formularioId={formularioId}/>;
        break;
      case 'template5':
        templateComponent = <Calendario buttonPressed={buttonPressed} formularioId={formularioId}/>;
        break;
      case 'template6':
        templateComponent = <Imagens buttonPressed={buttonPressed} formularioId={formularioId}/>;
        break;
      case 'template7':
        templateComponent = <Download buttonPressed={buttonPressed} formularioId={formularioId}/>;
        break;
      default:
        break;
    }
  
    setTemplateComponent(templateComponent);
    

    console.log('Estado do botão:', buttonPressed);
  };
  

  
  
  function returnToMenu() {
    const createURL = `/menu?email=${email}`;
     navigate(createURL); // Navega para a página do menu com o parâmetro email
  }

  const handleModeloClick = () => {
    setModeloSelected(true);
    setGeralSelected(false);
  };

  const handleGeralClick = () => {
    setModeloSelected(false);
    setGeralSelected(true);
  };

  const handleFormularioSubmit = async (event) => {
    event.preventDefault();
    const formularioId = await criarFormulario(); // Obtenha o formularioId ao chamar a função criarFormulario
    setFormSubmitted(true);
  
    console.log('Escolha:', escolha);
    console.log('Formulário enviado:', formularioId);
  
    if (escolha === 'recrutamento') {
      try {
        await axios.put(`${Adress.backendUrl}/api/atualizar-isrec/${formularioId}`);
        console.log('isrec atualizado para true');
      } catch (error) {
        console.error('Erro ao atualizar isrec:', error);
      }
    }
  };
  
  return (
    <div className="FormsC">
      <Navbar2/>

      <form onSubmit={handleFormularioSubmit}>

      {formSubmitted ? null : (
       
       <>

          <div className='topcreatepage'>  

            <div className='top-name'>
              <input
                type="text"
                className='Nomeform'
                name="nomeFormulario"
                value={formularioNome}
                onChange={handleFormularioNomeChange}
                placeholder="Nome do Formulário"
              />

              <button type="submit" className='nomebtn'>Adicionar</button> 
            </div>
              <div className='formtype'>
                <label>
                  <input
                    type="radio"
                    value="normal"
                    checked={escolha === 'normal'}
                    onChange={() => setEscolha('normal')}
                  />
                  Normal
                </label>

                <label>
                  <input
                    type="radio"
                    value="recrutamento"
                    checked={escolha === 'recrutamento'}
                    onChange={() => setEscolha('recrutamento')}
                  />
                  Recrutamento
                </label>
              </div>
          </div>
          
            </>
          )}
        </form>

      {formSubmitted != false && (
        <>
        <div className='topcafter'>  
          <div className='add-T'>  
              <div className="template-example">
            {formSubmitted && templateComponent}
            </div>
          
          </div>
          <div className="add-page-button">
                <img src={buttonplus} alt="botão de mais" onClick={handlePage} />
              </div>
          </div>
        <div>
          <div className='staydown'>
          <div className='middlecreatepage'>
              <div className="button-container">
              <button className={`Modelobtn-${modeloSelected ? 'select' : ''}`} onClick={handleModeloClick}>
                Modelo
              </button>
              
              <button className={`Geralbtn-${geralSelected ? 'select' : ''}`} onClick={handleGeralClick}>
                Geral
              </button>
            </div>
          </div>
          <div className='bottomcreatepage'>
            
            
                  <div className="image-container">
                    <img src={icon_1_1}  alt="Ícone 1" onClick={() => handleButtonClick('template1')} />
                    <img src={icon_2_1}  alt="Ícone 2" onClick={() => handleButtonClick('template2')} />
                    <img src={icon_3_1}  alt="Ícone 3" onClick={() => handleButtonClick('template3')} />
                    <img src={icon_4_1}  alt="Ícone 4" onClick={() => handleButtonClick('template4')} />
                    <img src={icon_5_1}  alt="Ícone 5" onClick={() => handleButtonClick('template5')} />
                    <img src={icon_6_1}  alt="Ícone 6" onClick={() => handleButtonClick('template6')} />
                    <img src={icon_7_1}  alt="Ícone 7" onClick={() => handleButtonClick('template7')} />
                  </div>
              </div> 
              
            </div>
            
          </div>
           </>
          )}
        </div>

   
  );
}

export default FormsCreatepage;
