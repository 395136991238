import React, { useState } from 'react';
import Logout from '../../assets/img/Logout.svg';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Logout.css';

const LogoutButton = () => {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const logOut = async () => {
    // Realize as ações necessárias para o logout
    setUser(null);
    setProfile(null);
    setIsLoggedIn(false);
    localStorage.removeItem('token');

    // Atualize o estado do usuário no backend
    try {
      await axios.put(`http://localhost:8000/api/user-activation/${user.id}/`);
      console.log('User state updated successfully');
    } catch (error) {
      console.log('Error updating user state:', error);
    }

    navigate('/login', { replace: true });

    // Redirecione para a página de login ou outra página desejada
    window.location.href = '/login';
  };

  return (
      <div className='logout'>
      <button className="logout-button" onClick={logOut}>
        Logout
      </button>
      </div>
    
  );
}

export default LogoutButton;

