import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import jekforms from '../../assets/img/jekforms.svg';
import createform from '../../assets/img/createform.svg';
import Navbar from '../../components/Navbar2/Navbar2';

import './Menupage.css';
import Lupa from '../../assets/img/lupa.jpg';
import { BiDotsVerticalRounded } from "react-icons/bi";
import { SlMagnifier } from "react-icons/sl";
import {RiDeleteBin6Line} from "react-icons/ri"
import {AiOutlineLink} from "react-icons/ai"
import {BiCommentDetail} from 'react-icons/bi'

import Adressfront from './../../adressfront.js';
import Adress from './../../adress.js';

const Menupage = () => {
  const [isUserActive, setIsUserActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [formularios, setFormularios] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isUserActiveParam = params.get('is_active');
  const email = params.get('email');
  const [Tam, setTam] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState({});


  useEffect(() => {
    const checkUserActivation = async () => {
      try {
        setIsUserActive(Boolean(isUserActiveParam));
      } catch (error) {
        console.log('Error checking user activation:', error);
      }
    };

    checkUserActivation();
  }, [isUserActiveParam]);

  useEffect(() => {
    const getFormularios = async () => {
      console.log(email);
      try {
        const response = await axios.get(`${Adress.backendUrl}/api/get-formulario/`, {
          params: { email: email }
        });
        const data = response.data;
        const formulariosData = data.map((item) => item.formulario);
        setFormularios(formulariosData);
        setTam(formulariosData.length)
        console.log(formulariosData)
        console.log(formulariosData.length);
      } catch (error) {
        console.log('Error retrieving formularios:', error);
      }
    };
  
    getFormularios();
  }, [email]);

  const toggleDropdown = (formularioId) => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [formularioId]: !prevState[formularioId]
    }));

    
  };

  const handleCreateForm = () => {
    const CreateURL = `/create-forms?email=${email}`;
    window.location.href = CreateURL;
  };

  const handleSearchForm = () => {
    console.log('Searching for:', searchQuery);
    const filteredFormularios = formularios.filter((formulario) =>
      formulario.nome.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFormularios(filteredFormularios);
    setSearchQuery('');
  };


  const handleDeleteForm = async (formularioId) => {
    try {
      await axios.delete(`${Adress.backendUrl}/api/delete_Form/${formularioId}`);
      window.location.reload();
    } catch (error) {
      console.error('Erro ao excluir o formulário:', error);
    }
  };

 const handleFormButtonClick = (formularioId) => {
    
    const FormularioURL = `/Show-Forms?Fd=${formularioId}`;
    window.open(FormularioURL, '_blank');
  };

  const handleIconClick = (formularioId, fname) => {
    const FormularioURL = `/Answer?formulario_id=${formularioId}&email=${email}&Fname=${fname}`;
    navigate(FormularioURL); 
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  

  return (
    <div className="container-Menu">

      <div className='top'>
      <Navbar />
      </div>

      <div className='middle'> 

          <div className='middle1'>
            <div className='search-container'>
              <div className='lupa'>
                  <SlMagnifier onClick={handleSearchForm} />
              </div>
              <input
                className="pesquisa"
                type="text"
                value={searchQuery}
                onChange={handleInputChange}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleSearchForm();
                  }
                }}
                placeholder="Pesquisa"
              />
            </div>
            </div>      
          
         <div className='buttons-container'>
            <div className='btn-wrapper'>
              <button className="Activebtn"  >Active</button>
              <button className="Inactivebtn"  >Inactive</button>
            </div>
        
        
            <button className="createbtn" onClick={handleCreateForm}>
            Create new form
            </button>
      
          </div>

       </div>
     
      <div className='bottom'>
          <div className='column-layout'>
            {formularios.map((formulario, index) => (
            <button
              key={formulario.id}
              className={`formulario-button-${index % 2 === 0 ? 'par' : 'impar'}`}
            >
              <div className='button-content'  onClick={() => handleFormButtonClick(formulario.id)}>
                {formulario.nome}
              </div>
              <div className={`ellipsis-`} onClick={() => toggleDropdown(formulario.id)}>
                <BiDotsVerticalRounded />
              </div>
              {dropdownOpen[formulario.id] && (
                <div className="dropdown-content">
                  <p id='ic1' onClick={() => handleDeleteForm(formulario.id)}><RiDeleteBin6Line /></p>
                  <p id='ic2' onClick={() => alert(`${Adressfront.frontendUrl}/Show-Forms?Fd=${formulario.id}`)}><AiOutlineLink /></p>
                  <p id='ic3' onClick={() => handleIconClick(formulario.id, formulario.nome)}><BiCommentDetail/></p>
                </div>
              )}
            </button>
            ))}
          </div>
      
          <button className="createbtn2" onClick={handleCreateForm}>
            Create new form
          </button>
        
      </div>
    </div>
  );
};

export default Menupage;
