import React from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import jekforms from '../../assets/img/jekforms.svg';
import Logout from './../LogOut/Logout';
import './Navbar2.css';



function Navbar2 () {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const email = params.get('email');

    function returnToMenu() {
        const createURL = `/menu?email=${email}`;
        navigate(createURL);
      }

    return ( 
    <div className='navcontainer'>
    <div className='navbar2'>
         
            <img src={jekforms}  alt='jekforms logo' onClick={returnToMenu}/>
           
            <div className='Logout'>
                <Logout/>
            </div>    
        
    </div>
    </div>
    );
};

export default Navbar2;
